<template>
  <div class="content_body" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form
            :inline="true"
            size="small"
            @keyup.enter.native="handleSearch"
          >
            <el-form-item label="等级名称">
              <el-input
                v-model="Name"
                placeholder="输入会员等级名称搜索"
                clearable
                @clear="handleSearch"
              ></el-input>
            </el-form-item>
            <el-form-item label="有效性">
              <el-select
                v-model="Active"
                placeholder="请选择有效性"
                clearable
                @change="changeActive"
              >
                <el-option label="是" :value="true"></el-option>
                <el-option label="否" :value="false"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                size="small"
                @click="handleSearch"
                v-prevent-click
                >搜索</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button
            type="primary"
            size="small"
            @click="showAddDialog"
            v-prevent-click
            >新增</el-button
          >
        </el-col>
      </el-row>
    </div>
    <div class="martp_10">
      <el-table
        :data="customerLevelTableData"
        size="small"
        tooltip-effect="light"
      >
        <el-table-column prop="Name" label="等级名称"></el-table-column>
        <el-table-column label="移动" min-width="150px">
          <template slot-scope="scope">
            <el-button
              size="small"
              type="primary"
              circle
              icon="el-icon-upload2"
              @click="upOneClick(scope.row, scope.$index)"
            ></el-button>
            <el-button
              size="small"
              type="primary"
              circle
              icon="el-icon-top"
              @click="upClick(scope.row, scope.$index)"
            ></el-button>
            <el-button
              size="small"
              type="primary"
              circle
              icon="el-icon-bottom"
              @click="downClick(scope.row, scope.$index)"
            ></el-button>
            <el-button
              size="small"
              type="primary"
              circle
              icon="el-icon-download"
              @click="downOneClick(scope.row, scope.$index)"
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column label="成长值">
          <template slot-scope="scope">
            {{ scope.row.GrowthValue }}
          </template>
        </el-table-column>
        <el-table-column label="第一次现金返佣">
          <template slot-scope="scope">
            {{ scope.row.OneReturnedCommission + '%' }}
          </template>
        </el-table-column>
        <el-table-column label="第一次卡金返佣">
          <template slot-scope="scope">
            {{ scope.row.OneCardReturnedCommission + '%' }}
          </template>
        </el-table-column>
        <el-table-column label="第二次现金及以上返佣">
          <template slot-scope="scope">
            {{ scope.row.TwoReturnedCommission + '%' }}
          </template>
        </el-table-column>
         <el-table-column label="第二次卡金及以上返佣">
          <template slot-scope="scope">
            {{ scope.row.TwoCardReturnedCommission + '%' }}
          </template>
        </el-table-column>
        <el-table-column label="有效性">
          <template slot-scope="scope">{{
            scope.row.Active ? "是" : "否"
          }}</template>
        </el-table-column>
        <el-table-column label="操作" width="80px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="showEditDialog(scope.row)"
              v-prevent-click
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--编辑、新增弹框-->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="170px"
        class="demo-ruleForm"
        size="small"
      >
        <el-form-item label="等级名称" prop="Name">
          <el-input
            v-model="ruleForm.Name"
            placeholder="请输入等级名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="成长值" prop="GrowthValue">
          <el-input
            v-enterInt
            v-model="ruleForm.GrowthValue"
            placeholder="请输入等级名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="第一次现金返佣" prop="OneReturnedCommission">
          <el-input
            v-enterInt
            v-model="ruleForm.OneReturnedCommission"
            placeholder="请输入等级名称"
          >
          <template slot="append">%</template>
          </el-input>
        </el-form-item>
         <el-form-item label="第一次卡金返佣" prop="OneCardReturnedCommission">
          <el-input
            v-enterInt
            v-model="ruleForm.OneCardReturnedCommission"
            placeholder="请输入等级名称"
          >
          <template slot="append">%</template>
          </el-input>
        </el-form-item>
        <el-form-item label="第二次及以上现金返佣" prop="TwoReturnedCommission">
          <el-input
            v-enterInt
            v-model="ruleForm.TwoReturnedCommission"
            placeholder="请输入等级名称"
          >
          <template slot="append">%</template>
          </el-input>
        </el-form-item>
        <el-form-item label="第二次及以上卡金返佣" prop="TwoCardReturnedCommission">
          <el-input
            v-enterInt
            v-model="ruleForm.TwoCardReturnedCommission"
            placeholder="请输入等级名称"
          >
          <template slot="append">%</template>
          </el-input>
        </el-form-item>
        <el-form-item label="是否有效" prop="Active" v-if="whichDialog == '1'">
          <el-radio-group v-model="ruleForm.Active">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small" v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="modalLoading"
          @click="submitForm('ruleForm')"
          v-prevent-click
          >保 存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/CRM/Customer/customerLevel";

export default {
  name: "CustomerLevel",
  data() {
    return {
      loading: false,
      modalLoading: false,
      Name: "", // 搜索框值
      Active: true, // 有效性
      customerLevelTableData: [], // 会员等级表格数据
      dialogVisible: false, // 编辑、新增弹框状态
      whichDialog: "", // 弹框类型
      dialogTitle: "", // 弹框标题
      ruleForm: {
        Name: "", // 等级名称
        Active: true, // 是否有效
        ID: "", // 会员ID
      }, // 编辑、新增表单数据
      rules: {
        Name: [{ required: true, message: "请输入等级名称", trigger: "blur" }],
        Active: [
          { required: true, message: "请选择是否有效", trigger: "change" },
        ],
        GrowthValue: [
          { required: true, message: "请输入成长值", trigger: "blur" },
        ],
        OneReturnedCommission: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        TwoReturnedCommission: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        OneCardReturnedCommission:[
          {required: true, message: "请输入", trigger: "blur"}
        ],
        TwoCardReturnedCommission:[
          {required: true, message: "请输入", trigger: "blur"}
        ],
      },
    };
  },
  methods: {
    // 搜索
    handleSearch() {
      var that = this;
      that.getCustomerLevel();
    },
    // 改变有效性
    changeActive() {
      var that = this;
      that.handleSearch();
    },
    // 新增按钮点击事件
    showAddDialog() {
      var that = this;
      that.whichDialog = "0";
      that.ruleForm = {
        Name: "", // 等级名称
        Active: true, // 是否有效
        ID: "",
      };
      that.dialogTitle = "新增会员等级";
      that.dialogVisible = true;
    },
    // 编辑
    showEditDialog(row) {
      var that = this;
      that.whichDialog = "1";
      that.ruleForm = Object.assign({},row);
      that.dialogTitle = "编辑会员等级";
      that.dialogVisible = true;
    },
    // 获取会员等级列表
    getCustomerLevel() {
      var that = this;
      that.loading = true;
      var params = {
        Name: that.Name,
        Active: that.Active,
      };
      API.getCustomerLevel(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.customerLevelTableData = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 编辑、增加弹框确认按钮点击事件
    submitForm(formName) {
      var that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          if (that.whichDialog == "0") {
            that.createCustomerLevel();
          } else {
            that.updateCustomerLevel();
          }
        }
      });
    },
    // 新增会员等级
    createCustomerLevel() {
      var that = this;
      that.modalLoading = true;
      const {
        GrowthValue,
        Name,
        OneReturnedCommission,
        TwoReturnedCommission,
        OneCardReturnedCommission,
        TwoCardReturnedCommission
      } = that.ruleForm;
      var params = {
        GrowthValue,
        Name,
        OneReturnedCommission,
        TwoReturnedCommission,
        OneCardReturnedCommission,
        TwoCardReturnedCommission
      };
      API.createCustomerLevel(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogVisible = false;
            that.getCustomerLevel();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 更新会员等级
    updateCustomerLevel() {
      var that = this;
      that.modalLoading = true;
      API.updateCustomerLevel(that.ruleForm)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogVisible = false;
            that.getCustomerLevel();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 移动至顶部
    upOneClick(row) {
      var that = this;
      that.moveCustomerSource(row.ID, "");
    },
    // 向上移动
    upClick(row, index) {
      var that = this;
      var beforeId = "";
      if (index > 1) {
        beforeId = that.customerLevelTableData[index - 2].ID;
      }
      that.moveCustomerSource(row.ID, beforeId);
    },
    // 向下移动
    downClick(row, index) {
      var that = this;
      var beforeId = "";
      var customerLevelList = [];
      customerLevelList = that.customerLevelTableData;
      if (index + 1 != customerLevelList.length) {
        beforeId = customerLevelList[index + 1].ID;
      }
      that.moveCustomerSource(row.ID, beforeId);
    },
    // 移动至底部
    downOneClick(row, index) {
      var that = this;
      var beforeId = "";
      var tableLength = 0;
      tableLength = that.customerLevelTableData.length;
      if (index < tableLength - 1) {
        beforeId = that.customerLevelTableData[tableLength - 1].ID;
      }
      that.moveCustomerSource(row.ID, beforeId);
    },
    // 移动会员等级
    moveCustomerSource(MoveID, BeforeID) {
      var that = this;
      that.loading = true;
      var params = {
        MoveID: MoveID,
        BeforeID: BeforeID,
      };
      API.moveCustomerSource(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.getCustomerLevel();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
  mounted() {
    var that = this;
    that.getCustomerLevel();
  },
};
</script>

<style scoped>
</style>